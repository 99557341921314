@mixin visuallyHidden {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

@mixin visuallyVisible {
	clip: unset;
	clip-path: unset;
	height: unset;
	overflow: unset;
	position: unset;
	white-space: unset;
	width: unset;
}

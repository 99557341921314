@import '../../styles/mixins.scss';

table.table {
	--separator-color: var(--text-color-4);

	width: 100%;

	&,
	thead,
	tbody,
	tr,
	th {
		display: block;
	}

	thead tr th,
	tbody tr td {
		vertical-align: middle;
	}

	tbody {
		tr {
			&[tabindex='0']:hover {
				cursor: pointer;
			}

			&:not(:first-child) {
				margin-top: 1.5rem;
			}

			td {
				padding: 0.25rem 0 0.75rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;

				&:hover {
					background: var(--background-hover-color);
				}

				&:not(:first-child) {
					border-top: 1px solid var(--separator-color);
				}

				&:first-child {
					padding: 0.75rem 0 0.25rem;

					> .label,
					> .value {
						width: 100%;
						margin-top: 0;
					}

					> .label {
						font-size: 0.8rem;
						font-weight: 300;
					}

					> .value {
						color: var(--text-color-1);
						font-size: 1.6rem;
					}
				}

				> .label,
				> .value {
					margin: 0.5rem 0 0;
				}

				> .label {
					color: var(--text-color-1);
				}

				> .value {
					color: var(--text-color-2);
				}
			}
		}
	}

	@media (max-width: 720px) {
		thead {
			@include visuallyHidden();
		}
	}

	@media (min-width: 720px) {
		display: table;
		border-collapse: collapse;

		tbody tr td,
		thead tr th {
			height: 45px;
			border-bottom: 1px solid var(--separator-color);
			border-top: none;
			padding: 0 0.75rem;
			margin: 0;
			display: table-cell;
			font-size: 1rem;

			&:first-child {
				padding: 0 0.75rem 0 1.5rem;
			}

			&:last-child {
				padding-right: 1.5rem;
			}

			> span {
				font-size: 1rem;
			}

			&.shrink {
				width: 0;
			}
		}

		thead {
			display: table-header-group;

			tr {
				display: table-row;

				th {
					text-align: left;
					color: var(--text-color-2);
					font-weight: 600;
					letter-spacing: 0.04em;
					display: table-cell;
				}
			}
		}

		tbody {
			display: table-row-group;

			tr {
				display: table-row;

				&:last-child > td {
					border-bottom: none;
					height: 44px;
				}

				&[tabindex='0']:hover {
					cursor: pointer;
					background: var(--background-hover-color);
				}

				td {
					font-weight: 300;

					> .label,
					> .value {
						margin: 0;
					}

					> .label {
						@include visuallyHidden();
					}

					> .value {
						color: var(--text-color-1);
						padding: 0.5rem 0;

						> .btn {
							--height: 40px;
						}
					}

					&:hover {
						background: none;
					}

					&:first-child {
						> .value {
							font-size: 1rem;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
}

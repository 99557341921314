.loading-spine {
	&.active svg {
		animation: spine 0.8s linear infinite forwards;
		will-change: transform;
	}

	&.failed svg {
		animation: spine 1s linear infinite forwards;
		will-change: transform;
	}
}

@keyframes spine {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.loading-container {
	position: relative;
	height: 100%;
	width: 100%;
	--size: 60px;
	--inset-factor: 5px;
	min-height: calc(var(--size) * 2);
	min-width: calc(var(--size) * 2);

	&.loading--large {
		--size: 120px;
		--inset-factor: 8px;

		> div {
			border-width: 4px;
		}
	}

	&.loading--small,
	&.loading--medium {
		--size: 20px;
		--inset-factor: 4px;
		height: var(--size);
		width: var(--size);
		min-height: unset;
		min-width: unset;

		> div {
			border-width: 3px;

			&.loading-1 {
				animation-duration: 1.25s;
				border-top-color: var(--brand-color-1);
				border-right-color: var(--brand-color-1);
			}

			&.loading-2 {
				animation-duration: 0.75s;
				border-bottom-color: var(--brand-color-2);
				border-right-color: var(--brand-color-2);
			}
		}
	}

	&.loading--medium {
		--size: 32px;
		--inset-factor: 6px;
	}

	&.loading--overlay {
		position: fixed;
		height: 100vh;
		width: 100vw;
		background: var(--backdrop-color);
	}

	> div {
		position: absolute;
		border-radius: 50%;
		border-width: 3px;
		border-style: solid;
		border-color: transparent;
		top: calc(50% - (var(--size) / 2) + var(--inset));
		left: calc(50% - (var(--size) / 2) + var(--inset));
		height: calc(var(--size) - var(--inset) * 2);
		width: calc(var(--size) - var(--inset) * 2);

		&.loading-1 {
			--inset: 0px;
			height: var(--size);
			width: var(--size);
			border-top-color: var(--brand-color-1);
			animation: spine 2s linear infinite;
		}

		&.loading-2 {
			--inset: calc(var(--inset-factor));
			border-bottom-color: var(--brand-color-1);
			animation: spine 1.25s linear infinite;
		}

		&.loading-3 {
			--inset: calc(var(--inset-factor) * 2);
			border-left-color: var(--brand-color-1);
			animation: spine 0.75s linear infinite;
		}
	}

	&.loading--pause {
		> div {
			&.loading-1,
			&.loading-2,
			&.loading-3 {
				animation: none;
			}
		}
	}
}

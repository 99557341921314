.stack {
	display: flex;
	margin: 0.75rem 0;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&.stack--row {
		flex-direction: row;

		&.stack--horizontal--start {
			justify-content: flex-start;
		}
		&.stack--horizontal--center {
			justify-content: center;
		}
		&.stack--horizontal--end {
			justify-content: flex-end;
		}
		&.stack--horizontal--space-between {
			justify-content: space-between;
		}
		&.stack--horizontal--stretch {
			justify-content: stretch;
		}
		&.stack--vertical--start {
			align-items: flex-start;
		}
		&.stack--vertical--center {
			align-items: center;
		}
		&.stack--vertical--end {
			align-items: flex-end;
		}
		&.stack--vertical--space-between {
			align-items: space-between;
		}
		&.stack--vertical--stretch {
			align-items: stretch;
		}
	}

	&.stack--column {
		flex-direction: column;

		&.stack--horizontal--start {
			align-items: flex-start;
		}
		&.stack--horizontal--center {
			align-items: center;
		}
		&.stack--horizontal--end {
			align-items: flex-end;
		}
		&.stack--horizontal--space-between {
			align-items: space-between;
		}
		&.stack--horizontal--stretch {
			align-items: stretch;
		}
		&.stack--vertical--start {
			justify-content: flex-start;
		}
		&.stack--vertical--center {
			justify-content: center;
		}
		&.stack--vertical--end {
			justify-content: flex-end;
		}
		&.stack--vertical--space-between {
			justify-content: space-between;
		}
		&.stack--vertical--stretch {
			justify-content: stretch;
		}
	}

	&.stack--padding {
		padding: 1.5rem;
	}

	&.stack--remove-margin > * {
		margin-top: 0;
		margin-bottom: 0;
	}
}

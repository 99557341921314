@use "~inter-ui/default" with (
	$inter-font-display: swap,
	$inter-font-path: '~inter-ui/Inter (web)'
);
@use "~inter-ui/variable" with (
	$inter-font-display: swap,
	$inter-font-path: '~inter-ui/Inter (web)'
);

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

a:hover,
a:focus,
a:active {
	outline: none;
	text-decoration: none;
	box-shadow: none;
}

body {
	line-height: 1.5;
}

.row.custom-row {
	margin-right: -12px;
	margin-left: -12px;

	> .col,
	> [class*='col-'] {
		padding-right: 12px;
		padding-left: 12px;
	}
}

.transak_modal {
	z-index: 1200 !important;
}

.transak_modal-overlay {
	z-index: 1199 !important;
}
.transak_close {
	background-color: $title !important;

	& path {
		fill: white !important;
	}
}

.definition-list {
	--separator-color: var(--text-color-4);
	margin: 0.75rem 0;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	> .definition-list-item {
		padding: 0.25rem 0 0.75rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		&:not(:first-child) {
			border-top: 1px solid var(--separator-color);
		}

		&.definition-list-item--vertical {
			> .definition-list-item-label,
			> .definition-list-item-value {
				width: 100%;
			}
		}

		> .definition-list-item-label,
		> .definition-list-item-value {
			margin: 0.5rem 0 0;
		}

		// > .definition-list-item-label {
		//   color: var(--text-color-1);
		// }

		// > .definition-list-item-value {
		//   color: var(--text-color-2);
		// }
	}

	&.definition-list--icon {
		display: flex;
		border-top: 1px solid var(--separator-color);
		border-bottom: 1px solid var(--separator-color);
		padding: 0.75rem 0;

		> .definition-list-item {
			padding: 0;
			flex: 1 1 0;
			justify-content: center;

			&:not(:first-child) {
				border-top: none;
			}

			> .definition-list-item-label {
				margin: 0 0.2rem 0 0;
				height: 1.2em;
				// color: var(--text-color-2);

				> div {
					height: 100%;

					> img,
					> svg {
						height: 100%;
					}

					> svg {
						&:not(.fill) {
							stroke: currentColor;
						}

						&.fill {
							fill: currentColor;
						}
					}
				}
			}

			> .definition-list-item-value {
				margin: 0;
			}
		}
	}
}

.segmented-controls {
	--background-stack: var(--transparent-background-dark);
	--disabled-color: var(--text-color-3);
	--active-background: var(--black);
	--active-text-color: var(--white);
	--inactive-text-color: var(--text-color-2);
	--inactive-background-hover: var(--text-color-4);
	--inactive-text-color-hover: var(--text-color-1);
	--border-radius: 0.5rem;
	display: flex;
	margin: 0.75rem 0;
	padding: 0.5rem;
	list-style: none;
	background: var(--background-stack);
	border-radius: var(--border-radius);
	width: 100%;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	// > :first-child {
	// 	border-top-left-radius: calc(var(--height) / 2);
	// 	border-bottom-left-radius: calc(var(--height) / 2);
	// }

	// > :last-child {
	// 	border-top-right-radius: calc(var(--height) / 2);
	// 	border-bottom-right-radius: calc(var(--height) / 2);
	// }

	> :not(:first-child) {
		margin-left: 0.5rem;
	}

	> * {
		// background: var(--color-purpleblue);
		border-radius: calc(var(--border-radius) - 0.35rem);

		&:hover {
			background: var(--inactive-background-hover);
		}
	}

	&.segmented-controls--vertical {
		flex-direction: column;

		// > :first-child {
		// 	border-top-left-radius: calc(var(--height) / 2);
		// 	border-top-right-radius: calc(var(--height) / 2);
		// 	border-bottom-left-radius: 0;
		// }

		// > :last-child {
		// 	border-top-right-radius: 0;
		// 	border-bottom-left-radius: calc(var(--height) / 2);
		// 	border-bottom-right-radius: calc(var(--height) / 2);
		// }

		> :not(:first-child) {
			margin-top: 6px;
			margin-left: 0;
		}

		&.segmented-controls--equal {
			> .segment {
				flex: 1 1 var(--height);
			}
		}
	}

	&.segmented-controls--equal {
		> .segment {
			flex: 1 1 0;
		}
	}

	&:focus-visible {
		outline: none;

		> .segment[aria-checked='true'] {
			outline: 2px solid var(--focus-color);
			outline-offset: 2px;
			border-color: transparent;
		}
	}

	> .segment {
		flex: 1 1 auto;
		--height: 44px;
		font-family: inherit;
		font-size: 1em;
		font-weight: 500;
		letter-spacing: 0.03em;
		border: none;
		height: var(--height);
		padding: 0 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--inactive-text-color);
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			cursor: pointer;
			background-image: var(--inactive-background-hover);
			color: var(--inactive-text-color-hover);
		}

		&:disabled {
			border-color: var(--disabled-color);
			pointer-events: none;
			color: var(--disabled-color);
		}

		&[aria-checked='true'] {
			pointer-events: none;
			background: var(--active-background);
			color: var(--active-text-color);
		}

		&.input-wrapper {
			padding: 0;
			flex: 1 0 auto;

			&:hover {
				background-image: none;
			}

			> input {
				padding: 0 1rem;

				&:focus-visible {
					outline: 2px solid var(--focus-color);
					outline-offset: 2px;
				}
			}

			&[aria-checked='true'] {
				pointer-events: initial;
				background: var(--active-background);

				> input {
					color: var(--active-text-color);
				}
			}
		}
	}

	&[aria-disabled='true'] {
		// background: transparent;
		pointer-events: none;

		> .segment {
			color: var(--disabled-color);

			&[aria-checked='true'] {
				background: var(--background-stack);
			}
		}
	}
}

.label-row {
	display: flex;
	justify-content: space-between;
	padding: 0 1rem;
	color: var(--primary-text-color);
	font-size: 0.875rem;
	margin: 0 0 0.75rem;

	@media (min-width: 620px) {
		padding: 0 1.5rem;
	}

	.primary-label {
		font-weight: 400;
		margin-bottom: 0; // needed because of other global css
	}

	.secondary-label {
		font-weight: 600;
		letter-spacing: 0.03em;
	}
}

// Colors
// Theme Colors
$primary: #5d5aff;
$secondary: #7f30de;
$tertiary: #f46ef2;
$dark-element: rgba(255, 255, 255, 0.06);
$light-element: rgba(32, 32, 32, 0.06);
// Status Colors
$danger: #eb6b6b;
$success: #4ac8aa;
$warning: #f3ba2f;
$title-dark: #fff;
$title: #202020;
$modal-bg-dark: rgba(32, 32, 32, 1);
$modal-bg: rgba(255, 255, 255, 1);

// Gradients
$primary-grad: linear-gradient(-345.39deg, #a890fe -17.91%, #87dce1 68.92%);

// Replace default colors
$theme-colors: (
	'primary': $primary,
	'secondary': $secondary,
	'teriary': $tertiary,
	'danger': $danger,
	'warning': $warning,
	'success': $success,
	'light-element': $light-element,
	'dark-element': $dark-element,
);

$colors: (
	'blue': $primary,
	'purple': $secondary,
	'red': $danger,
	'yellow': $warning,
);

// Bootstrap Customization
$body-color: #fff;

$btn-border-radius: 1.125rem;
$btn-border-radius-lg: 1.125rem;
$btn-border-radius-sm: 1.125rem;
$btn-padding-y: 0.625rem;
$btn-padding-x: 1.5rem;

$input-border-width: 0;
$input-border-radius: 1.125rem;
$input-border-radius-lg: 1.125rem;
$input-height: 56px;
$input-group-addon-border-color: transparent;

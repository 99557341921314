.grid {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 1.5rem;

	.grid-column {
		grid-column-start: auto;

		&.align-center {
			display: flex;
			justify-content: center;
		}
	}

	@media (min-width: 900px) {
		grid-template-columns: repeat(12, 1fr);

		.grid-column {
			&[data-width='1/12'] {
				grid-column-start: span 1;

				.grid {
					grid-template-columns: repeat(1, 1fr);

					.grid-column {
						grid-column-start: auto;
					}
				}
			}

			&[data-width='1/6'],
			&[data-width='2/12'] {
				grid-column-start: span 2;

				.grid {
					grid-template-columns: repeat(1, 1fr);

					.grid-column {
						grid-column-start: auto;
					}
				}
			}

			&[data-width='1/4'],
			&[data-width='3/12'] {
				grid-column-start: span 3;

				.grid {
					grid-template-columns: repeat(1, 1fr);

					.grid-column {
						grid-column-start: auto;
					}
				}
			}

			&[data-width='1/3'],
			&[data-width='2/6'],
			&[data-width='4/12'] {
				grid-column-start: span 4;

				.grid {
					grid-template-columns: repeat(1, 1fr);

					.grid-column {
						grid-column-start: auto;
					}
				}
			}

			&[data-width='5/12'] {
				grid-column-start: span 5;
			}

			&[data-width='1/2'],
			&[data-width='2/4'],
			&[data-width='3/6'],
			&[data-width='6/12'] {
				grid-column-start: span 6;
			}

			&[data-width='7/12'] {
				grid-column-start: span 7;
			}

			&[data-width='2/3'],
			&[data-width='4/6'],
			&[data-width='8/12'] {
				grid-column-start: span 8;
			}

			&[data-width='3/4'],
			&[data-width='9/12'] {
				grid-column-start: span 9;
			}

			&[data-width='5/6'],
			&[data-width='10/12'] {
				grid-column-start: span 10;
			}

			&[data-width='11/12'] {
				grid-column-start: span 10;
			}

			&[data-width='1/1'],
			&[data-width='2/2'],
			&[data-width='3/3'],
			&[data-width='4/4'],
			&[data-width='6/6'],
			&[data-width='12/12'] {
				grid-column-start: span 12;
			}
		}
	}
}

.input-container {
	--background-stack: var(--transparent-background-dark);
	--placeholder-color: var(--text-color-2);
	--placeholder-color-focus: var(--text-color-1);
	--disabled-color: var(--text-color-3);
	--border-radius: 0.5rem;
	margin: 0.75rem 0;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	> .input-container-above {
		align-items: center;

		&.input-container-above--controls-only {
			justify-content: flex-end;
		}

		> .secondary-label {
			display: flex;
			align-items: center;
		}

		.input-container-mobile-controls {
			margin-left: 0.75rem;
			margin-right: -1rem;

			> .btn {
				--height: 36px;
				padding: 0 0.5rem;
			}

			@media (min-width: 620px) {
				display: none;
			}
		}
	}

	&.input-container--disabled {
		> .input-container-above {
			color: var(--disabled-color);
		}
	}

	> .input-container-main {
		--height: 44px;
		display: flex;
		align-items: center;
		border-radius: var(--border-radius);
		transition: border-color 300 msease-in-out, color 500ms ease-in-out;
		min-height: var(--height);

		// > :first-child {
		// 	border-top-left-radius: calc(var(--height) / 2);
		// 	border-bottom-left-radius: calc(var(--height) / 2);
		// }

		// > :last-child {
		// 	border-top-right-radius: calc(var(--height) / 2);
		// 	border-bottom-right-radius: calc(var(--height) / 2);
		// }

		> :not(:first-child) {
			margin-left: 6px;
		}

		> * {
			background: var(--background-stack);
		}

		button:focus-visible,
		input:focus-visible,
		textarea:focus-visible {
			outline: 2px solid var(--focus-color);
			outline-offset: 2px;
		}

		@media (min-width: 620px) {
			--height: 56px;
		}
	}

	@media (min-width: 620px) {
		&.input-container--small > .input-container-main {
			--height: 44px;
		}
	}
}

.input-wrapper {
	width: 100%;
	flex: 1 1 auto;
	position: relative;
	min-height: var(--height);
	border-radius: var(--border-radius);

	&.input--error {
		> input,
		> textarea {
			color: var(--brand-color-2);

			&:focus-visible {
				outline-color: var(--brand-color-2);
			}
		}
	}

	> input,
	> textarea {
		outline: none;
		border-radius: inherit;
		border: none;
		// transition: color 300ms ease-in-out;
		color: var(--primary-text-color);
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 300;
		background: transparent;
		width: 100%;
		padding: 0 1rem;
		height: var(--height);
		white-space: nowrap;
		font-family: Kanit, sans-serif;
		font-size: 1rem;
		-webkit-appearance: textfield;

		&::-webkit-search-decoration {
			-webkit-appearance: none;
		}

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		&::placeholder {
			color: var(--placeholder-color);
			font-weight: 300;
			opacity: 0.5;
			font-family: Kanit, sans-serif;
			font-size: 1rem;
		}

		&:focus::placeholder {
			color: var(--placeholder-color-focus);
		}

		&[type='number'] {
			-moz-appearance: textfield;
		}

		&:disabled {
			color: var(--disabled-color);

			+ .input-wrapper-context {
				color: var(--disabled-color);
			}
		}

		@media (min-width: 620px) {
			padding: 0 1.5rem;
		}
	}

	> textarea {
		--padding: calc((var(--height) - 1.5rem) / 2);
		padding-top: var(--padding);
		padding-bottom: var(--padding);
		resize: vertical;
		min-height: var(--height);
		width: 100%;
	}

	> .input-wrapper-context {
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 0;
		height: 100%;
		display: none;
		justify-content: flex-end;
		align-items: center;

		@media (min-width: 620px) {
			display: flex;
			padding: 8px 8px 8px 0;
		}

		> .btn {
			--inner-height: var(--height);
			background: var(--color-purple);
			border: none;
			border-radius: 0 calc(var(--inner-height) / 2) calc(var(--inner-height) / 2) 0;
			color: var(--primary-text-color);
			font-size: 0.8rem;
			padding: 0.25rem 0.5rem;
			height: var(--inner-height);
			max-height: var(--inner-height);
			font-weight: 500;

			@media (min-width: 620px) {
				--inner-height: 40px;
				font-size: 1rem;
				border-radius: calc(var(--inner-height) / 2);
				padding: 0.25rem calc(1.5rem - (var(--height) - var(--inner-height)) / 2);
			}

			// @media (min-width: 768px) {
			//   height: 40px;
			//   max-height: 40px;
			//   padding: 0.5rem;
			//   top: 8px;
			//   right: 8px;
			// }

			&:hover {
				background-image: var(--secondary-gradient-shifted);
			}
		}

		> span.unit {
			margin-right: 1rem;
		}
	}
}

.card {
	padding: 1.25rem;
	border-radius: 1rem;
	border: 2px solid var(--color-darkblue);
	color: var(--text-color-1);

	@media (min-width: 620px) {
		padding: 2rem;
	}

	&.card--large {
		box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
		padding: 60px 30px 40px;
		border-radius: 20px;
	}

	&.card--warning {
		color: var(--warning-color);
		border-color: var(--warning-color);
		background: var(--warning-background-color);

		.card-heading > .card-heading-main {
			color: var(--warning-color);
		}
	}

	&.card--critical {
		color: var(--critical-color);
		border-color: var(--critical-color);
		background: var(--critical-background-color);

		.card-heading > .card-heading-main {
			color: var(--critical-color);
		}
	}

	.card-header {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		@media (min-width: 620px) {
			flex-wrap: nowrap;
		}

		.card-header-image,
		.card-header-accessory {
			flex: 0 0 auto;
			display: flex;
			align-items: center;
		}

		.card-header-image {
			width: 60px;
			margin-right: 1rem;

			img,
			svg {
				width: 100%;
			}
		}

		.card-heading {
			margin: 0;
			flex: 1 1 auto;

			&:not(:last-child) {
				margin-right: 1.5rem;
			}

			> .card-heading-main {
				display: flex;
				align-items: center;
				font-size: 1.4rem;
				line-height: 1em;

				&:not(:only-child) {
					margin-top: 0.25rem;
				}

				> .badge {
					margin-left: 1rem;
				}
			}

			&.card-heading--with-subtitle {
				display: flex;
				flex-direction: column;

				> .card-heading-sub {
					order: 1;
					font-size: 0.95rem;
					font-weight: 300;
					line-height: 1.6em;
				}

				> .card-heading-main {
					order: 2;
				}
			}
		}

		&.card-header--empty + .card-body {
			padding-top: 0;
		}

		.card-header-accessory {
			> .btn,
			> .menu > .btn {
				margin: 0;

				&.btn-icon {
					border-color: var(--background-color);

					&:last-of-type {
						margin-right: -12px;

						@media (min-width: 620px) {
							margin-right: 0;
						}
					}
				}
			}
		}

		@media (min-width: 620px) {
			.card-header-image {
				margin-right: 1.5rem;
			}

			.card-heading > .card-heading-main {
				font-size: 1em;
			}

			h1,
			h2,
			h3 {
				&.card-heading > .card-heading-main {
					font-size: 0.8em;
				}
			}

			.card-header-accessory {
				> .menu {
					margin-right: 0;
				}

				> .btn,
				> .menu > .btn {
					border-color: var(--brand-color-2);
				}
			}
		}
	}

	.card-body {
		padding-top: 30px;

		&:empty,
		&:only-child {
			padding-top: 0;
		}

		p {
			margin: 0.75rem 0 0;

			&:first-child {
				margin-top: 0;
			}
		}
	}
}

button.card {
	background: transparent;

	> .card-body {
		display: flex;
		align-items: center;
	}

	&:hover {
		cursor: pointer;
		border-color: var(--brand-color-2);
	}

	&:disabled {
		opacity: 0.5;
	}

	@media (max-width: 1199px) {
		max-height: 70px;
	}
}

#root[aria-hidden="true"] {
  height: 100%;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;

  @media (min-width: 620px) {
    height: unset;
    overflow: unset;
    opacity: unset;
    pointer-events: unset;
  }
}

.modal-backdrop {
  z-index: 1100;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    height: 100%;
    // overflow-y: ${({ mobile }) => (mobile ? "scroll" : "hidden")};
    // align-self: ${({ mobile }) => (mobile ? "flex-end" : "center")};
    // ${({ theme }) => theme.mediaWidth.upToMedium`
    //   width: 65vw;
    //   margin: 0;
    // `}
    // ${({ theme, mobile }) => theme.mediaWidth.upToSmall`
    //   width:  85vw;
    //   ${
    //     mobile &&
    //     css`
    //       width: 100vw;
    //       border-radius: 20px;
    //       border-bottom-left-radius: 0;
    //       border-bottom-right-radius: 0;
    //     `
    //   }
    // `}

    @media (min-width: 620px) {
      margin: 0 0 2rem 0;
      overflow: hidden;
      width: 50vw;
      height: auto;
      min-width: 340px;
      max-width: var(--modal-max-width, unset);
      min-height: var(--modal-min-height, unset);
      max-height: var(--modal-max-height, unset);
    }

    .modal-semantic {
      display: flex;
      flex-direction: column;
      width: 100%;

      > header,
      > footer {
        flex: 0 0 auto;
        border-color: var(--separator-color);
        border-style: solid;
        border-width: 0;
      }

      > header {
        border-bottom-width: 2px;
        padding: 1.5rem 1.5rem 0.75rem;

        > .modal-semantic-title-row {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .modal-semantic-title {
            margin: 0;
            font-size: 1.5em;
          }

          > button {
            margin: 0;
          }
        }
      }

      > footer {
        border-top-width: 2px;
        padding-top: 0.75rem;
        padding: 0.75rem 1.5rem 1.5rem;
        display: flex;
        justify-content: center;

        > button {
          flex: 1 0 auto;
          max-width: 50%;
        }
      }

      > .modal-semantic-content {
        flex: 1 1 0;
        padding: 0.75rem 1.5rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &:last-child {
          padding-bottom: 1.5rem;
        }

        &.modal-semantic-content--scrollable {
          overflow-y: auto;
        }

        &.modal-semantic-content--autosizer-scroll {
          padding: 0.75rem 0;

          > div > div > * {
            padding: 0 1.5rem;
          }
        }

        > .modal-semantic-content-section {
          padding: 0.75rem 1.5rem;
          margin: 0 -1.5rem;
          flex: 0 0 auto;

          &:not(:first-child) {
            border-top: 2px solid var(--separator-color);
          }

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            margin-bottom: -1.5rem;
            padding-bottom: 1.5rem;
          }

          &.modal-semantic-content-section--scrollable {
            overflow-y: auto;
            height: 100%;
            flex: 1 1 0;
            padding: 0;

            > * {
              padding: 0.75rem 1.5rem;
            }

            &:last-child > :last-child {
              padding-bottom: 1.5rem;
            }
          }

          &.modal-semantic-content-section--joinafter {
            padding-bottom: 0;

            + .modal-semantic-content-section {
              border-top-width: 0;
              padding-top: 0;
            }
          }
        }
      }
    }
  }
}

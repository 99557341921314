.tabs {
	> ul.tabs-list {
		list-style: none;
		margin: -1rem 0 2rem;
		padding: 0;
		display: flex;
		flex-wrap: wrap;

		> li {
			margin-right: 1rem;
			margin-top: 1rem;

			> a {
				padding: 1rem 2rem;
				border-radius: 0.75rem;
				color: var(--brand-color-1);
				background: var(--consumer-light-blue);
				display: flex;

				> img {
					margin-left: 0.5rem;
					height: 24px;
					width: 24px;
				}
			}

			&:not(.selected) > a:hover {
				color: var(--brand-color-1-fade);
				background: var(--consumer-lighter-gray);
			}

			&.selected > a {
				background: var(--brand-color-1);
				color: var(--body-color);
			}
		}
	}

	> .tabs-panels {
		> .tab-panel {
			display: none;

			&.selected {
				display: block;
			}
		}
	}
}

section.tabs.container {
	max-width: 640px;
}

@media (min-width: 768px) {
	section.tabs.container {
		max-width: 768px;
	}
}

@media (min-width: 1024px) {
	.tabs {
		> .tabs-panels {
			> .tab-panel {
				padding: 2rem;
			}
		}
	}

	section.tabs.container {
		max-width: 1024px;
	}
}

.badge {
  display: block;
  height: 32px;
  border-radius: 16px;
  line-height: 32px;
  text-align: center;
  font-size: 0.9rem;
  letter-spacing: 0.02em;
  font-weight: 500;
  color: var(--text-color-1);
  padding: 0 1rem;

  &.badge--info {
    background: var(--color-purple);
  }

  &.badge--highlighted {
    background: var(--secondary-gradient);
  }

  &.badge--disabled {
    background: var(--disabled-color);
    color: var(--background-color);
  }
}

.progress-bar {
	margin: 1.5rem 0;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	.progress-bar-labels {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.25rem;

		.progress-bar-label {
			color: var(--text-color-2);
			letter-spacing: 0.04em;
		}

		.progress-bar-totalvalue {
			color: var(--text-color-1);
			white-space: nowrap;
			margin-left: 0.5rem;
		}

		.progress-bar-percent {
			color: var(--text-color-1);
			font-weight: 600;
			white-space: nowrap;
			margin-left: 0.5rem;
		}
	}

	.progress-bar-display {
		height: 6px;
		width: 100%;
		border-radius: 3px;
		background: var(--consumer-light-blue);
		position: relative;

		> .progress-bar-progress {
			position: absolute;
			height: 100%;
			width: attr(data-percent);
			background: var(--brand-color-1);
			border-radius: 3px;
		}
	}
}

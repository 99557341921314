@import '../../styles/mixins.scss';

.btn-g {
	--height: 52px;
	--padding: 0 1.5rem;
	--text-color: var(--brand-color-1);
	--background: var(--body-background);
	--text-color-hover: var(--brand-color-1-fade);
	--background-hover: var(--consumer-lighter-gray);
	border-radius: 0.375rem;
	// border-radius: calc(var(--height) / 2);
	background: var(--background);
	font-family: inherit;
	font-weight: 400;
	font-size: 1rem;
	// border: 1px solid var(--brand-color-2);
	min-height: var(--height);
	padding: var(--padding);
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0.75rem 0;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	.btn-g-label {
		color: var(--text-color);
		padding: 6px 7px;

		&:not(:first-child) {
			text-align: left;
		}

		&:not(:last-child) {
			text-align: right;
		}
	}

	&.btn-g--borderless {
		border: none;
	}

	svg {
		width: 20px;
		flex: none;

		&:not(.fill) > *,
		&:not(.fill) g > * {
			stroke: var(--text-color);
		}

		&.fill > *,
		&.fill g > * {
			fill: var(--text-color);
		}
	}

	&:not(.btn-g-icon):not(.btn-g-icon--responsive) {
		svg {
			margin-left: -0.55rem;

			+ .btn-g-label {
				padding-left: 0.5rem;
				margin-right: -0.5rem;
			}
		}
	}

	@media (min-width: 500px) {
		&.btn-g-icon--responsive {
			svg {
				margin-left: -0.55rem;

				+ .btn-g-label {
					padding-left: 0.5rem;
					margin-right: -0.5rem;
				}
			}
		}
	}

	&:hover {
		--text-color: var(--text-color-hover);
		--background: var(--background-hover);
		cursor: pointer;
	}

	&:disabled,
	&[aria-disabled='true'] {
		--text-color: var(--text-color-disabled);
		--background: var(--background-disabled);
		pointer-events: none;
		opacity: 0.65;
	}

	&:focus-visible {
		// outline: 2px solid var(--focus-color);
		// outline-offset: 2px;
		// border-color: transparent;
		box-shadow: 0 0 0 0.2rem var(--text-color-4);
	}

	&.btn-g--large {
		--height: 56px;
		padding: 0.5rem 1.5rem;
		font-size: 1rem;

		@media (min-width: 500px) {
			min-width: 10rem;
		}
	}

	&.btn-g-icon,
	&.btn-g-icon--responsive {
		width: var(--height);
		padding: 0;

		&:hover {
			background-image: var(--secondary-gradient);
		}

		> .btn-g-label {
			@include visuallyHidden();
		}
	}

	@media (min-width: 500px) {
		&.btn-g-icon--responsive {
			width: auto;
			padding: var(--padding);

			&:hover {
				background-image: var(--secondary-gradient-shifted);
			}

			> .btn-g-label {
				@include visuallyVisible();
			}
		}
	}

	&.full-width {
		width: 100%;
	}

	&.btn-g--active {
		--text-color: var(--white);
		--background: var(--brand-color-1);
		--text-color-hover: var(--white);
		--background-hover: var(--brand-color-1-fade);
	}

	&.btn-g--loading-only {
		position: relative;

		> svg,
		> .btn-g-label {
			opacity: 0;
		}

		> .loading-container {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: auto;
			width: auto;
		}
	}

	> .loading-container {
		flex: none;

		> div {
			&.loading-1 {
				border-top-color: var(--btn-text-color);
				border-right-color: var(--btn-text-color);
			}

			&.loading-2 {
				border-bottom-color: var(--btn-text-color);
				border-right-color: var(--btn-text-color);
			}
		}
	}

	&.btn-g-primary {
		--text-color: var(--white);
		--background: var(--brand-color-1);
		--text-color-hover: var(--white);
		--background-hover: var(--brand-color-1-fade);
	}
}

a.btn-g {
	background: var(--background);
	color: var(--text-color);
	text-decoration: none;

	&:hover {
		--text-color: var(--text-color-hover);
		--background: var(--background-hover);
		cursor: pointer;
	}
}

.btn-row {
	margin-top: 1.5rem;

	> .btn-g {
		margin-top: 0;
		margin-bottom: 0;

		&:not(:first-child) {
			margin-left: 0.75rem;
		}
	}
}

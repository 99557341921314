#layout {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto auto auto auto;
	grid-template-areas:
		'header'
		'nav'
		'main'
		'footer';

	> header {
		grid-area: header;
	}

	> nav {
		grid-area: nav;
		background: var(--body-background);
	}

	> main {
		grid-area: main;
		background: var(--body-background);
		padding: 2rem 1.25rem;
	}

	> footer {
		grid-area: footer;
	}

	@media (min-width: 1024px) {
		grid-template-columns: auto 1fr;
		grid-template-rows: auto auto auto;
		grid-template-areas:
			'header header'
			'nav main'
			'footer footer';

		> main {
			padding: 4rem 3rem 4rem 0;
		}
	}
}

@font-face {
	font-family: 'Circular';
	src: url('CircularXXWeb-Medium.woff2') format('woff2'), url('CircularXXWeb-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Circular';
	src: url('CircularXXWeb-Bold.woff2') format('woff2'), url('CircularXXWeb-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

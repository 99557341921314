@import '../styles/mixins.scss';

:root {
	// base
	--white: white;
	--black: black;

	--brand-color-1: #0296a6;
	--brand-color-1-fade: #006570;

	// Text
	--text-color-1: #131e2e;
	--text-color-2: #5f6a7a;
	--text-color-3: #3f5879;
	--text-color-4: #dee0e3;

	--transparent-background: rgba(255, 255, 255, 0.06);
	--transparent-background-dark: rgba(16, 16, 36, 0.03);
	--body-background: #f3f5f8;
	--body-color: #f3f5f8;

	--foundation-text-color-2: #b8babc;

	--disabled-color: var(--consumer-light-blue);

	--consumer-dark: #12171d;
	--consumer-black: #131e2e;
	--consumer-light-blue: #f3f5f8;
	--consumer-gray: #7d7d7d;
	--consumer-light-gray: #bcbcbc;
	--consumer-lighter-gray: #dee0e3;
}

.visually-hidden {
	@include visuallyHidden();
}

a:hover {
	color: var(--brand-color-1);
}
